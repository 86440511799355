.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f8f8;
    color: #333;
    font-family: Arial, sans-serif;
}

.not-found-title {
    font-size: 6rem;
    margin: 0;
    color: #D72B63;
}

.not-found-message {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

.redirect-message {
    font-size: 1rem;
    color: #666;
}