.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* display: grid; */
    display: grid;
    justify-content: center;
    align-items: center;

    /* max-width: 80%;
    max-height: 80%; */
    overflow-y: auto;
}

.modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.footer .save-button {
    margin-left: auto;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
}

.footer {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #ccc;
    margin-top: 20px;
}

.footer .cancel-button-alone {
    margin-left: auto;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background-color: #dc3545;
    color: #fff;
}

/* Cancel button */
.footer .cancel-button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background-color: #dc3545;
    color: #fff;
}

.text-label {
    width: 150px;
    height: 40px;
    padding: 8px;
    font-size: 16px;
}

.text-label-report {
    width: 350px;
    height: 40px;
    padding: 8px;
    font-size: 16px;
}

.text-label-teams {
    width: 90px;
    height: 40px;
    padding: 8px;
    font-size: 16px;
}

.text-input {
    width: 250px;
    height: 40px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #333;
}

.text-input-multiline {
    width: 250px;
    height: 120px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #333;
    resize: none;
}

.text-input-number {
    width: 125px;
    height: 40px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #333;
    margin-right: 125px;
}


.text-input-number input {
    background-color: #f2f2f2;
    width: 90px;
}

.uploader .p-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
}

.uploader .p-button-danger {
    padding: 0px;
    color: #333;
    background-color: #fff;
}

.p-image-toolbar button {
    color: #fff;
}

.p-fileupload-content {
    height: 150px;
}

.action-buttons {
    width: 30px;
    height: 30px;
}

.action-buttons:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
}

.action-buttons:focus {
    outline: none;
}

.add-container .add-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #4CAF50;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-button:hover {
    background-color: #45a049;
}

/* .add-button-sub {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #4CAF50;
    border: none;
    color: white;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.plus-icon {
    user-select: none;
    line-height: 1;
}

.add-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding: 20px 80px 0 0
}

.flex-start {
    justify-content: flex-start;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.modal-title {
    font-size: 20px;
    font-weight: bold;
}

.modal-close {
    cursor: pointer;
    font-size: 24px;
    color: #bbbbbb;
}

.dropdown {
    width: 250px;
    height: 40px;
    /* padding: 8px; */
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #333;
}

.margin-top-20 {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .modal {
        max-width: 100%;
        position: fixed;
        top: 0;
    }
}