.login-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 5%;
    width: 600px;
    background: #fff;
    padding-bottom: 30px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text {
    color: #1976D2;
    font-size: 35px;
    font-weight: 700;
}

.underline {
    width: 10%;
    height: 4px;
    background-color: #1976D2;
    border-radius: 9px;
}

.inputs {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 60px;
    background: #eaeaea;
    border-radius: 6px;
}

.icon-image {
    margin: 0px 30px;
    height: 45%;
}

.input input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.forgot-password {
    flex-direction: column;
    padding-left: 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 18px;
}

.forgot-password span {
    color: #1976D2;
    cursor: pointer;
}

.submit-container {
    display: flex;
    gap: 20px;
    margin: 40px auto;
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 480px;
    height: 60px;
    color: #fff;
    background: #1976D2;
    border-radius: 6px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.error-handling {
    display: flex;
    gap: 20px;
    margin: 10px auto;
    color: #d21919
}